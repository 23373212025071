<section class="mt-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 mb-4">
                <mat-card class="cust_card">
                    <div class="d-flex">
                        <div class="col-12 mb-3">
                            <!-- <h2 class="text-center">{{ selectedTemplateName }}</h2> -->
                        </div>
                    </div>
                    <div class="mx-6">
                        <form [formGroup]="form">
                            <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>  
                        </form> 
                       
                        <div class="d-flex">
                            <div class="col-12 mb-3">
                                <button class="float-end me-4 mt-3" mat-raised-button color="secondary" type="submit">Cancel Application</button>
                                <button class="float-end me-4 mt-3" mat-raised-button color="secondary" type="submit" (click)="exitApplication()">Exit</button>
                                <button class="float-end me-4 mt-3" mat-raised-button color="secondary" type="submit">Save for later</button>
                                <button class="float-end blue_button me-4 mt-3" mat-flat-button type="submit" >Continue</button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</section> 