import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CommonModule } from '@angular/common';
import { Materials } from './material';
import { FormlyCustomCheckboxComponent } from './shared/formly-custom-checkbox/formly-custom-checkbox.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormlyCustomTabs } from './shared/utils/formly-custom-tabs';
import { FormlyCustomSection } from './shared/utils/formly-custom-section';
import { FormlyCustomRow } from './shared/utils/formly-custom-row';
import { FormlyCustomColumn } from './shared/utils/formly-custom-column';
import { FormlyCustomButton } from './shared/utils/formly-custom-button';
import { FormlyCustomIndividualTable } from './shared/utils/formly-custom-individual-table';
import { FormlyCustomRadio } from './shared/utils/formly-custom-radio';
import { FormlyTooltipWrapper } from './shared/utils/formly-tooltip-wrapper';
import { PhoneMaskType } from './shared/utils/phone-mask-type';
import { FormlyCustomTable } from './shared/utils/formly-custom-table';
import { FormlyFileCustom } from './shared/utils/formly-custom-file';
import { FormlyDynamicModalComponent } from './shared/popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { UnAuthComponent } from './shared/popups/un-auth/un-auth.component';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserVerificationComponent } from './user-verification/user-verification.component';
import { VerifyDomainComponent } from './verify-domain/verify-domain.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CookieService } from 'ngx-cookie-service';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { DynamicApplicationComponent } from './dynamic-application/dynamic-application.component';
import { DynamicApplicationDemoComponent } from './dynamic-application-demo/dynamic-application-demo.component';
import { ExpansionPanelTypeComponent } from './shared/utils/formly-field-expansion-panel';
import { AccordionTypeComponent } from './shared/utils/formly-field-accordion';
import { ApplicationAccessVerificationComponent } from './application-access-verification/application-access-verification.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
// export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

export function minLengthValidationMessages(error: any, field: FormlyFieldConfig) {
  return `Should have atleast ${field.props?.minLength} characters`;
}

export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}

export function animationExtension(field: FormlyFieldConfig) {
  if (field.wrappers && field.wrappers.includes('animation')) {
    return;
  }

  field.wrappers = ['animation', ...(field.wrappers || [])];
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    FormlyCustomCheckboxComponent,
    FormlyCustomTabs,
    FormlyCustomSection,
    FormlyCustomRow,
    FormlyCustomColumn,
    FormlyCustomButton,
    FormlyCustomIndividualTable,
    FormlyCustomRadio,
    FormlyTooltipWrapper,
    PhoneMaskType,
    FormlyCustomTable,
    FormlyFileCustom,
    FormlyDynamicModalComponent,
    UnAuthComponent,
    UserVerificationComponent,
    VerifyDomainComponent,
    DynamicApplicationComponent,
    DynamicApplicationDemoComponent,
    AccordionTypeComponent,
    ExpansionPanelTypeComponent,
    ApplicationAccessVerificationComponent,
    ApplicationStatusComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    AppRoutingModule,
    Materials,
    NgxMaskModule.forRoot({thousandSeparator: ','}),
    FormlyMaterialModule,
    FormlyModule.forRoot({
      validators: [{ name: 'fieldMatch', validation: fieldMatchValidator }],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minLength', message: minLengthValidationMessages },
      ],
      types: [
        { name: 'tabs', component: FormlyCustomTabs },
        { name: 'section', component: FormlyCustomSection },
        { name: 'row', component: FormlyCustomRow },
        { name: 'column', component: FormlyCustomColumn },
        { name: 'button', component: FormlyCustomButton },
        { name: 'multi-checkbox', component: FormlyCustomCheckboxComponent },
        { name: 'table', component: FormlyCustomTable },
        { name: 'individual-table', component: FormlyCustomIndividualTable },
        // { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'file', component: FormlyFileCustom },
        { name: 'phone', component: PhoneMaskType,
          defaultOptions: {
            templateOptions: {
              type: 'tel',
            },
          },
        },
        {
          name: 'text',
          extends: 'phone',
          defaultOptions: {
            templateOptions: {
              type: 'text',
            },
          },
        },
        { name: 'custom-radio', component: FormlyCustomRadio },
        { name: 'accordion', component: AccordionTypeComponent },
        { name: 'expansionPanel', component: ExpansionPanelTypeComponent },
      ],
      wrappers: [
        { name: 'tooltip', component: FormlyTooltipWrapper },
      ],
     
    }),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent],
})

export class AppModule { }
