import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { ApiService } from './services/api.service';
import { CookieService } from 'ngx-cookie-service';
import packageInfo from '../../package.json';
import version from '../assets/version.json';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public version: string = packageInfo.version;
  title = 'standardc-frontend-web';
  createOnlineSub:Subscription;
  constructor( 
    private spinner: NgxSpinnerService,
    private router: Router ,
     private _api : ApiService
     , private cookieService : CookieService) {
      this.createOnlineSub = this._api.createOnline$().subscribe((status) => {
        !status && this._api.openSnackBar('No Internet Connection Available', 'Error')
      });
      }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.spinner.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.spinner.hide();
      }
    });
    // this.getVersions();
    // sessionStorage.setItem( 'version', packageInfo.version);
    // if(packageInfo.version.toString() !== version.build_version.toString()){
    //   window.location.reload();
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   this.cookieService.deleteAll('/');
    // }
  }
  ngOnDestroy(): void {
    if (this.createOnlineSub) {
      this.createOnlineSub.unsubscribe();
    }  }
  getVersions(){
    this._api.functionGET('version').subscribe((response)=>{
      sessionStorage.setItem('server_version' , JSON.stringify(response['data']));
    });
  }

}
