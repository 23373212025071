import { Component } from "@angular/core";
import { FieldArrayType } from "@ngx-formly/core";

@Component({
    selector: 'formly-field-accordion',
    template: `
      <mat-accordion>
        <ng-container *ngFor="let field of field.fieldGroup">
          <formly-field [field]="field"></formly-field>
        </ng-container>
      </mat-accordion>
    `,
  })
  export class AccordionTypeComponent extends FieldArrayType {}
  