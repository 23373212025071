<section class="section">   
    <div class="container mt-3 mb-5">
        <div class="row ">
            <div class="col-md-6 offset-md-3">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Application Type:</strong> <label class="fs-5">Business Banking form</label>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Status:</strong> <label class="fs-5 text-white badge bg-danger pb-1">Declined</label>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Comments:</strong> <label class="fs-5">A Bank has recently closed your account</label>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Last Updated:</strong> <label class="fs-5">July 25, 2024 3:14 PM</label>
                                </div>
                            </div>
                                  
                        </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>
