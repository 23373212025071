import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';


@Component({
  selector: 'app-formly-custom-table',
  template: `
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data']">
          <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td>
          <td>
          <button class="blue_button mr-3" mat-raised-button (click)="openModal(row)">Add</button>
          </td>
        </tr>
      </tbody>
    </table>
  `,
})
export class FormlyCustomTable extends FieldType {
  constructor(private dialog: MatDialog) {
    super();
  }
  dataSource = [];
  openModal(row: any) {
    console.log('------------document table');
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: { data: row, tabType: 'document_tab', modalWidth:'30'},
        disableClose: true,
        
      });
  
      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          console.log('------------result doc ', result);
          // this.dataSource.push(result);
          // this.dataSource = [...this.dataSource]; 
        }
      });

  }
}
