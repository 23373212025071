<app-header></app-header>  
<div class="container-fluid cntAreaHeight p-0">
  <div class="d-flex h-100">
    <div class="contentWrapper">
      <div class="row h-100">
          <div class="col-12 align-self-start">
              <router-outlet></router-outlet>        
          </div>
        <div class="col-12 align-self-end">
            <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
bdOpacity = 0.9
bdColor = "rgba(51,51,51,0.29)"
size = "medium"
color = "#fff"
type = "ball-beat"
[fullScreen] = "true"
>
<p style="color: white" > Loading </p>
</ngx-spinner>