import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-formly-dynamic-modal',
  templateUrl: './formly-dynamic-modal.component.html',
  styleUrls: ['./formly-dynamic-modal.component.css']
})
export class FormlyDynamicModalComponent implements OnInit {
  tabType = '';
  searchinput: FormGroup;
  searchInput=''; 
  license_data: any;
  sortcolumn = '';
  sortorder = '';
  page = {
    pageSizeOptions: [10, 25, 50, 100]
    , limit: 25
    , pageSize: 25
    , index: 0
  };
  displayedColumns: string[] = [
    'Status'
    , 'Business Legal Name'
    , 'License Type'
    , 'Action'
  ];


  form = new FormGroup({});
  model = {
    dynamicData: [],
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  modalWidth: any;

  @Input() editModel: any;  // The selected row's data
  // @Input() editFields: FormlyFieldConfig[];  // Formly JSON configuration for the fields
  @Output() editIndividual = new EventEmitter<any>();  // Emit updated data back to parent

  editIndForm = new FormGroup({});

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder
    , private spinner: NgxSpinnerService
  ) {
    this.searchinput = this.formBuilder.group({
      search_field: new FormControl(''),
  });


  }

  ngOnInit(): void {

    this.tabType = this.data['tabType']
    console.log('----------------this.tabType', this.tabType);

    if(this.tabType === 'key_person_tab') {
      this.fields = this.data['indJsonForm'];
      this.modalWidth = this.data['modalWidth'];
      // console.log('===========indJsonForm', this.fields);
    }

    // if(this.tabType === 'edit_key_person_tab') {
    //   this.modalWidth = this.data['modalWidth'];
    //   this.editFields = this.data['editFields'];
    //   this.editModel = this.data['editModel']
    //   console.log('------------edit ind', this.data['editModel'])
    // }

    if(this.tabType === 'document_tab') {
      this.modalWidth = this.data['modalWidth'];
    }
    
}

   // Array to store selected users
   selectedUsers: any[] = [];

   // Handle checkbox change event
   onCheckboxChange(user: any, event: any) {
     if (event.target.checked) {
       this.selectedUsers.push(user);
       console.log('---------------selected', this.selectedUsers);
     } else {
       this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
     }
   }

   submitSelectedRow() {
    this.dialogRef.close(this.selectedUsers);
    console.log('--------------------------submitted', this.selectedUsers);

   }


  uploadDocform = new FormGroup({});
  uploadDocmodel = {};
  uploadDocfields: FormlyFieldConfig[] = [
    {
          "fieldGroup": [
            {
              "type": 'section',
              "templateOptions": { 
                "label": 'Action',
                "className": 'custom-section-header',
              },
              "fieldGroupClassName": 'custom-section',
              "fieldGroup": [
                {
                  "type": 'row',
                  "fieldGroup": [
                    {
                      "type": 'column',
                      "className": 'col-md-12',
                      "fieldGroup": [
                        {
                          "key": "docUpload",
                          "type": "radio",
                          "templateOptions": {
                            "required": true,
                            "options": [
                              { "value": "upload_from_device", "label": "Upload from Your Device" },
                              { "value": "not_applicable", "label": "Not Applicable" }
                            ]
                          }
                        },

                        {
                          "key": 'incorporationDocuments',
                          "type": 'file',
                          "templateOptions": {
                            // "label": 'Articles of Incorporation & Other Formation Documents',
                            "placeholder": 'Upload your documents',
                            "required": true,
                            // "multiple": true,
                            "accept": '.pdf,.doc,.docx,.jpg,.png',
                            "description": 'Please upload the required documents (PDF, DOC, DOCX, JPG, PNG).'
                          },
                          "hideExpression": "!model.docUpload || model.docUpload === 'not_applicable'",
                          "validation": {
                            "messages": {
                              "required": "Articles of Incorporation & Other Formation Documents is required."
                            }
                          }
                        }

                      ],
                    },

                    {
                      "type": 'column',
                      "className": 'col-md-12 mt-4',
                      "fieldGroup": [
                        {
                          "key": "comments",
                          "type": "textarea",
                          "templateOptions": {
                            "label": "Comments",
                            "required": true,
                            "rows": 5,
                            
                          },
                          "className": 'custom-textarea-wrapper',
                        },
                      ],
                    },

                  ],
                },
              ],
            },
          ],
    }
  ];

  close() {
    this.dialogRef.close();
  }

  submit() {
    console.log('yyyyyyyyyyyyyyyyyyyyyy', this.model);
    if (this.form.valid) {
      console.log(this.model);
      this.dialogRef.close(this.model);
    }

  }


  submitDocument() {
    if (this.uploadDocform.valid) {
      console.log(this.uploadDocmodel);
      this.dialogRef.close(this.uploadDocmodel);
    }

    // const file = this.uploadDocmodel.fileUpload;
    // if (file) {
    //   const formData = new FormData();
    //   formData.append('file', file, file.name);
  
    //   // Make your API call to upload the file here
    //   console.log('File to upload:', file);
    // }
  }


  onEditIndSubmit() {
    if (this.form.valid) {
      // Emit the updated model back to the parent component
      this.editIndividual.emit(this.model);
      // $('#editModal').modal('hide');  // Close modal after save
      this.dialogRef.close();
    }
  }

}
