<div class="modal-container">
    <div *ngIf="tabType === 'key_person_tab'">
              <div class="modal-header">
                <h2>Add Individual</h2>
                <button mat-icon-button (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
              </div>
    
              <form [formGroup]="form" (ngSubmit)="submit()" style="margin-bottom: 60px;">
                <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
              
                <button class="float-right mr-3" mat-raised-button color="primary" type="submit" >Submit</button>
                <button class="float-right mr-3" mat-raised-button color="secondary" (click)="close()">Close</button>
              </form>
    
    
              <!-- <form [formGroup]="form" (ngSubmit)="submit()">
                <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
                <div class="modal-footer">
                  <button mat-button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form> -->
           
    </div>
    
    <!-- <div *ngIf="tabType === 'edit_key_person_tab'">
      <div class="modal-header">
        <h2>Edit Individual</h2>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
      </div>
    
      <form [formGroup]="editIndForm" (ngSubmit)="onEditIndSubmit(editModel)" style="margin-bottom: 60px;">
        <formly-form [form]="editIndForm" [fields]="editFields" [model]="editModel"></formly-form>
      
        <button class="float-right mr-3" mat-raised-button color="primary" type="submit" >Update</button>
        <button class="float-right mr-3" mat-raised-button color="secondary" (click)="close()">Close</button>
      </form> -->
    
    
      <!-- <form [formGroup]="form" (ngSubmit)="submit()">
        <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
        <div class="modal-footer">
          <button mat-button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form> -->
    
    <!-- </div> -->
    <!-- 
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <formly-form [form]="form" [fields]="fields" [model]="editModel"></formly-form>
        <button type="submit" class="btn btn-success" *ngIf="isEditing">Save Changes</button>
      </form> -->
        
    <div *ngIf="tabType === 'document_tab'">
    
        <div class="modal-header">
          <h2>Add Document</h2>
          <button mat-icon-button (click)="close()">
              <mat-icon>close</mat-icon>
          </button>
        </div>
        <form [formGroup]="uploadDocform" (ngSubmit)="submitDocument()" class="mt-4">
          <formly-form [model]="uploadDocmodel" [fields]="uploadDocfields" [form]="uploadDocform"></formly-form>
            <button class="float-right mr-3" mat-raised-button color="primary" type="submit" >Submit</button>
            <button class="float-right mr-3" mat-raised-button color="secondary" (click)="close()" >Close</button>
        </form>
    
    </div>

    
    
    </div>