import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserVerificationComponent } from './user-verification/user-verification.component';
import { VerifyDomainComponent } from './verify-domain/verify-domain.component';
import { DynamicApplicationComponent } from './dynamic-application/dynamic-application.component';
import { DynamicApplicationDemoComponent } from './dynamic-application-demo/dynamic-application-demo.component';
import { ApplicationAccessVerificationComponent } from './application-access-verification/application-access-verification.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';

const routes: Routes = [
  {
    path:'',
    component:HomeComponent
  },
  {
    path: 'user-submission-form/:id',
    component: UserVerificationComponent,
  },
  {
    path: 'verify',
    component: VerifyDomainComponent,
  },
  {
    path: 'selected-application/:id',
    component: DynamicApplicationComponent,
  },
  {
    path: 'app-access-verification',
    component: ApplicationAccessVerificationComponent,
  },
  {
    path: 'app-status',
    component: ApplicationStatusComponent,
  },
  {
    path: 'dynamic-application-demo',
    component: DynamicApplicationDemoComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],  // Use 'useHash: true' for hash-based routing
  exports: [RouterModule]

  // imports: [RouterModule.forRoot(routes)],
  // exports: [RouterModule]
})
export class AppRoutingModule { }
