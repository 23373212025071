import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-application-access-verification',
  templateUrl: './application-access-verification.component.html',
  styleUrls: ['./application-access-verification.component.css']
})
export class ApplicationAccessVerificationComponent implements OnInit {
  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private formBuilder: FormBuilder
   , private cookieService: CookieService
   ) {
     this.userForm = formBuilder.group({
       motherSirName: new FormControl('', [Validators.required]),
       lastName: new FormControl('', [Validators.required]),
       email: new FormControl('', [Validators.required, Validators.email])
     })
   }

  ngOnInit(): void {
  }

  formValidation(tag: number){
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;

    if(!lastName){
      if(tag==1){
        this._api.openSnackBar('Enter Last Name', 'Error');
      }
      return;
    }

    if(!email){
      if(tag==1){
        this._api.openSnackBar('Enter Email', 'Error');
      }
      return;
    }
    if(!motherSirName){
      if(tag==1){
        this._api.openSnackBar('Enter Mother Maiden Name', 'Error');
      }
      return;
    }
  this.submitAccessVerification();

  }

  submitAccessVerification(){

    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;

    console.log('---------------------------form data', lastName, email, motherSirName);

  }

  goToHome(){
    this.router.navigate(['/']);
  }
}
