import { Component, HostListener, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private _api: ApiService
    , private spinner: NgxSpinnerService
  ) { }
  
  ngOnInit(): void {
    this.getDynamicHeader();
    window.onload = () => {
      this.getDynamicHeader();
    };

  }

getDynamicHeader(){
    this.spinner.show();
    const apiData = {
      captcha_token: '4455'
    }
    this._api.functionPOST('web/getWebHeaderFooter', apiData).subscribe((response) => {
    const logoURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['logo_path']+`?timestamp=${new Date().getTime()}`;
    const faviconURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['favicon_path']+`?timestamp=${new Date().getTime()}`;
    const headerContent = response['data']['web_hf']['header'];
    const footerContent = response['data']['web_hf']['footer'];
    const backgroundColor = response['data']['web_hf']['background_color'];
    const highLightColor = response['data']['web_hf']['highlight_color'];
    document.documentElement.style.setProperty('--dynamic-bg-color', highLightColor);
    document.documentElement.style.setProperty('--dynamic-section-bg-color', backgroundColor);

    this.setFavicon(faviconURL);
    this.setLogo(logoURL);
    this.setHeader(headerContent);
    this.setFooter(footerContent);
      this.spinner.hide();
    });
  }

  setLogo(url: string): void {
    const imgElement = document.getElementById('dynamicLogo') as HTMLImageElement;
    if (imgElement) {
        imgElement.src = url;
        imgElement.alt = 'Logo';
    }
  }
  
  setFavicon(url: string): void {
    const favicon = document.getElementById('dynamicFavicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = url;
    }
  }
  
  setHeader(header: string): void {
    const headerTitle = document.getElementById('dynamicHeader');
    if (headerTitle) {
      headerTitle.innerHTML = header;
    }
  }
  
  setFooter(footer: string): void {
    const footerTitle = document.getElementById('dynamicFooter');
    if (footerTitle) {
      footerTitle.innerHTML = footer;
    }
  }

}
