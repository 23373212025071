import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
    selector: 'formly-field-expansion-panel',
    template: `
      <mat-expansion-panel (opened)="openPanel()" (closed)="closePanel()">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ to.title }}</mat-panel-title>
          <mat-panel-description>{{ to.description }}</mat-panel-description>
        </mat-expansion-panel-header>
        <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
      </mat-expansion-panel>
    `,
    styles: [`
        ::ng-deep .mat-expansion-panel {
          margin-bottom: 10px !important;
        }
        ::ng-deep .mat-expansion-panel-header-description{
            color: green !important;
            padding: 4px;
            color: #fff;
            font-weight: 700 !important;
        }
        ::ng-deep .mat-expansion-panel-header-title{
            font-weight: 700 !important;
        }

      `]
  })
  export class ExpansionPanelTypeComponent extends FieldType {
    openPanel() {
      this.formControl.setValue(true);
    }
    
    closePanel() {
      this.formControl.setValue(false);
    }
  }
  