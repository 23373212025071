import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  dynamicFormList: any;
  selectedIndex = 0;
  showChildComponent = false;
  selectedTemplateID: any;

  constructor(
    private _api: ApiService
  , private spinner: NgxSpinnerService
  , private router: Router
  ) {}

ngOnInit(): void {

  this.deleteAllCookies();
  this.getDynamicForms();
  }

  deleteAllCookies() {
    console.log('-------------');
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

getDynamicForms() {
  this.spinner.show();
  const apiData = {
    page: 1,
    limit: 10,
    sortorder: "DESC",
    sortcolumn: "created",
  };
  this._api.functionPOST('web/getWebActiveTemplatesList', apiData).subscribe((response) => {
    this.dynamicFormList = response['data']['data'];
    this.spinner.hide();
  });
}

goToApp(event: any) {
  this.router.navigate(['user-submission-form/' + event + '']);
  console.log('-----------items', event);
}

}

