import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core'

@Component({
  selector: 'app-formly-custom-button',
  template: `
    <button
      mat-raised-button
      type="button"
      [class]="to.className"
      [disabled]="to.disabled || false"
      (click)="to['onClick']()">
      {{ to['text'] }}
    </button>
  `,
})
export class FormlyCustomButton extends FieldType {
//  [color]="to['color'] || 'primary'"
}
