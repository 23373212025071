<div class="container-fluid head_wrap">
  <div class="d-flex align-items-center ">
    <div class="logo">
      <div class="row align-items-center">
        <div class="col-auto">
          <a href="/">
            <img id="dynamicLogo" src="assets/images/logo.png" style="width: auto !important;height: 42px;" alt="Logo" class="logo-icon">
          </a>
        </div>
        <div class="col mt-3">
          <h1 id="dynamicHeader"></h1>
        </div>
      </div>
    </div>
   
  </div>
</div>
