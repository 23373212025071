import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';

@Component({
  selector: 'app-formly-custom-individual-table',
  template: `
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data']; let i = index">
          <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td>
          <td>
          <button class="blue_button mr-3" mat-raised-button (click)="editRow(i)">Edit</button>
          <button class="mr-3" mat-raised-button color="danger" (click)="deleteRow(i)">Delete</button>
            <!-- <div (click)="openModal(row)">Open Modal</div> -->
          </td>
        </tr>
      </tbody>
    </table>
  `,
})
export class FormlyCustomIndividualTable extends FieldType {
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    super();
  }
  dataSource = [];
  editIndModel = {};
  isEditing = false;
  editingIndex: number | null = null;
  indJsonForm: FormlyFieldConfig[] = [
    {
      "fieldGroup": [
        {
          "type": 'section',
          "templateOptions": { 
            "label": 'Beneficial Owner',
            "className": 'custom-section-header',
          },
          "fieldGroupClassName": 'custom-section',
          "fieldGroup": [
            {
              "type": 'row',
              "fieldGroup": [

                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "firstName",
                      "type": "input",
                      "templateOptions": {
                        "label": "First Name",
                        "required": true
                      },
                      "validation": {
                          "messages": {
                            "required": "First Name is required."
                          }
                        }
                    },
                  ],
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "middleInitial",
                      "type": "input",
                      "templateOptions": {
                        "label": "Middle Initial",
                        "required": true
                      },
                      "validation": {
                          "messages": {
                            "required": "Middle Initial is required."
                          }
                        }
                    },
                  ],
                },
              //   {
              //     "type": 'column',
              //     "className": 'col-md-6',
              //     "fieldGroup": [
              //       {
              //         "key": "lastName",
              //         "type": "input",
              //         "templateOptions": {
              //           "label": "Last Name",
              //           "required": true
              //         },
              //         "validation": {
              //             "messages": {
              //               "required": "Last Name is required."
              //             }
              //           }
              //       },
              //     ],
              //   },
              //   {
              //     "type": 'column',
              //     "className": 'col-md-6 custom-tooltip-class',
              //     "fieldGroup": [
              //       {
              //         "key": "suffix",
              //         "type": "input",
              //         "templateOptions": {
              //           "label": "Suffix"
              //         }
              //       },
              //     ],
              //   },
              //   {
              //     "type": 'column',
              //     "className": 'col-md-6',
              //     "fieldGroup": [
              //       {
              //         "key": "responsibility",
              //         "type": "custom-radio",
              //         "templateOptions": {
              //           "label": "Do you have significant responsibility for controlling, managing, or directing the legal business entity listed in this application?",
              //           "required": true,
              //           "options": [
              //             { "value": "Yes", "label": "Yes" },
              //             { "value": "No", "label": "No" }
              //           ]
              //         },
              //         "validation": {
              //             "messages": {
              //               "required": "Significant responsibility is required."
              //             }
              //           },
              //         "className": 'custom-radio'
              //       },
              //     ],
              //   },
              //   {
              //     "type": 'column',
              //     "className": 'col-md-6',
              //     "fieldGroup": [
              //       {
              //         "key": "ownership25Percent",
              //         "type": "custom-radio",
              //         "templateOptions": {
              //           "label": "Do you own 25% or more of the business listed in this application?",
              //           "required": true,
              //           "options": [
              //             { "value": "Yes", "label": "Yes" },
              //             { "value": "No", "label": "No" }
              //           ]
              //         },
              //         "validation": {
              //             "messages": {
              //               "required": "Business listed in this application is required."
              //             }
              //           },
              //         "className": 'custom-radio'
              //       },
              //     ],
              //   },
              //   {
              //     "type": 'column',
              //     "className": 'col-md-6 custom-tooltip-class mt-4',
              //     "fieldGroup": [
              //       {
              //     "key": "accountSigner",
              //     "type": "custom-radio",
              //     "templateOptions": {
              //       "label": "Account Signer?",
              //       "required": true,
              //       "options": [
              //         { "value": "yes", "label": "Yes" },
              //         { "value": "no", "label": "No" }
              //       ]
              //     },
              //     "validation": {
              //             "messages": {
              //               "required": "Account Signer is required."
              //             }
              //           },
              //         "className": 'custom-radio'
              //   }
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 mt-4',
              //   "fieldGroup": [
              //     {
              //       "key": "ownershipPercentage",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "% Ownership of the Business",
              //         "type": "number",
              //         "required": true
              //       },
              //       "validation": {
              //           "messages": {
              //             "required": "% Ownership of the Business is required."
              //           }
              //         },
              //       "expressionProperties": {
              //         'templateOptions.disabled': 'model.accountSigner !== "yes"'
              //       }
              //       // "hideExpression": "!model.accountSigner || model.accountSigner === 'no'"
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "jobTitle",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Job Title",
              //         "required": true
              //       },
              //       "validation": {
              //           "messages": {
              //             "required": "Job Title is required."
              //           }
              //         }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "mothersMaidenName",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mother’s Maiden Name",
              //         "required": true
              //       },
              //       "validation": {
              //           "messages": {
              //             "required": "Mother’s Maiden Name is required."
              //           }
              //         }
              //     },
              //   ],
              // },
              
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "birthDate",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Birth Date",
              //         "type": "date",
              //         "required": true
              //       },
              //       "validation": {
              //           "messages": {
              //             "required": "Birth Date is required."
              //           }
              //         }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "phone",
              //       "type": "phone",
              //       "templateOptions": {
              //         "label": "Phone",
              //         "required": true,
              //         "mask": "(000) 000-0000",
              //           "tooltip": "Phone Number is required."
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Phone is required."
              //         }
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "email",
              //       "type": "input",
              //      "templateOptions": {
              //         "label": "Email",
              //         "type": "email",
              //         "required": true,
              //         "pattern": "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$",
              //         "patternMessage": "Please enter a valid email address."
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Email ID is required",
              //           "pattern": "Please enter a valid email address."
              //         }
              //       }

              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "ssn",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Social Security Number",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Social Security Number is required."
              //         }
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "citizenship",
              //       "type": "select",
              //       "templateOptions": {
              //         "label": "Citizenship",
              //         "required": true,
              //         "options": [
              //           { "value": "US Citizen", "label": "US Citizen" },
              //           { "value": "Permanent Resident", "label": "Permanent Resident" },
              //           { "value": "Non-Permanent Resident", "label": "Non-Permanent Resident" }
              //         ]
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Citizenship is required."
              //         }
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "countryOfOrigin",
              //       "type": "select",
              //       "templateOptions": {
              //         "label": "If not a US Citizen, indicate country of Origin",
              //         "required": true,
              //         "options": [
              //           { "value": "Country1", "label": "Country1" },
              //           { "value": "Country2", "label": "Country2" }
              //         ]
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Country of Origin is required."
              //         }
              //       }
              //     },
              //   ],
              // },
              
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "authorizedSigner",
              //       "type": "custom-radio",
              //       "templateOptions": {
              //         "label": "Is this individual an authorized signer?",
              //         "required": true,
              //         "options": [
              //           { "value": "Yes", "label": "Yes" },
              //           { "value": "No", "label": "No" }
              //         ]
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Authorized signer is required."
              //         }
              //       },
              //       "className": 'custom-radio'
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialAddress1",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Residential Street Address Line 1",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Residential Street Address Line 1 is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialAddress2",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Residential Street Address Line 2"
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialCity",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Residential City",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Residential City is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialState",
              //       "type": "select",
              //       "templateOptions": {
              //         "label": "Residential State",
              //         "required": true,
              //         "options": []
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Residential State is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialZip",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Residential Zip",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Residential Zip is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialCounty",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Residential County",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Residential County is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "residentialCountry",
              //       "type": "select",
              //       "templateOptions": {
              //         "label": "Residential Country",
              //         "required": true,
              //         "options": [],
              //         "changeFunction": "onResidentialCountryChange"
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Residential County is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "sameAsMailing",
              //       "type": "custom-radio",
              //       "templateOptions": {
              //         "label": "Is the residential address the same as the mailing address?",
              //         "required": true,
              //         "options": [
              //           { "value": "Yes", "label": "Yes" },
              //           { "value": "No", "label": "No" }
              //         ]
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "At least one is required."
              //         }
              //       },
              //       "className": 'custom-radio'
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "photoIdNumber",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Photo ID Number",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Photo ID Number is required."
              //         }
              //       },
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 mt-4',
              //   "fieldGroup": [
              //     {
              //       "key": "photoIdIssueDate",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Photo ID Issue Date",
              //         "type": "date",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Photo ID Issue Date is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 mt-4',
              //   "fieldGroup": [
              //     {
              //       "key": "photoIdExpirationDate",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Photo ID Expiration Date",
              //         "type": "date",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Photo ID Expiration Date is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 mt-4',
              //   "fieldGroup": [
              //     {
              //       "key": "photoIdIssueDateState",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Photo ID State of Issue",
              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Photo ID State of Issue is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 mt-4',
              //   "fieldGroup": [
              //     {
              //       "key": "photoIdIssueDateCountry",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Photo ID Country of Issue",

              //         "required": true
              //       },
              //       "validation": {
              //         "messages": {
              //           "required": "Photo ID Country of Issue is required."
              //         }
              //       }
              //     }
              //   ]
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class mt-4',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingAddress",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mailing Address (if different than residential)"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingAddress1",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mailing Street Address Line 1"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingAddress2",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mailing Street Address Line 2"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingCity",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mailing City"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingState",
              //       "type": "select",
              //       "templateOptions": {
              //         "label": "Mailing State",
              //         "options": []
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingZip",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mailing Zip"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              // {
              //   "type": 'column',
              //   "className": 'col-md-6 custom-tooltip-class',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingCounty",
              //       "type": "input",
              //       "templateOptions": {
              //         "label": "Mailing County"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     },
              //   ],
              // },
              
              // {
              //   "type": 'column',
              //   "className": 'col-md-6',
              //   "fieldGroup": [
              //     {
              //       "key": "mailingCountry",
              //       "type": "select",
              //       "templateOptions": {
              //         "label": "Mailing Country",
              //         "options": [],
              //         "changeFunction": "onmailingCountryChange"
              //       },
              //       "expressionProperties": {
              //         "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
              //         // "templateOptions.required": "model.sameAsMailing === 'No'"
              //       }
              //     }
              //   ],
              // },
              ],
            },

          ],
        },
      ],
    },
  ]

  deleteRow(index: number): void {
    const currentData = this.model.dynamicData;
    currentData.splice(index, 1);
    this.model.dynamicData = currentData
  }

  editRow(index: number): void {
    console.log('------------individual edit', index);
    this.isEditing = true;
    this.editingIndex = index;
    // console.log('------------------------this.model', this.model)

    // console.log('------------------------this.model.dynamicData', this.model.dynamicData)
    // Populate the form with the data of the selected row
    this.editIndModel = { ...this.model.dynamicData[index] };
    // this.model.dynamicData = {
    //   first_name: this.model.dynamicData[index].first_name,
    //   last_name: this.model.dynamicData[index].last_name
    // };

    // console.log('--------------------selected row', this.model.dynamicData);
    this.openRightSlideModalEditInd(this.editIndModel)
  }

  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }
  
  openRightSlideModalEditInd(row: any) {
    console.log('Open Modal clicked');
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: { 
          tabType: 'edit_key_person_tab', 
          editFields: this.indJsonForm, 
          editModel: { ...row },
          modalWidth:'45'},
        disableClose: true,
      });

      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result) => {

        if (result) {
          console.log('-----------------edit result', result);
          // this.appendDataToTable(result);
        }
      });
  }

  // appendDataToTable(result: any) {
  //   const individualTableField = this.findFieldByKey(this.fields, 'individualTable');
  //   if (individualTableField) {
  //     const currentData = individualTableField.templateOptions.data || [];
  //     this.model.dynamicData = [...currentData, {
  //       first_name: result.firstName,
  //       last_name: result.middleInitial
  //     }];

  //     console.log('----------current data', this.model.dynamicData);
  //     individualTableField.templateOptions.data = this.model.dynamicData;
  //     this.cdr.detectChanges();
  //   }
  // }

}