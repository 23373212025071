// custom-radio-field.component.ts
import { Component, Input } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-radio',
  template: `
   <div class="form-group">
      <label [innerHTML]="to.label" [ngClass]="{ 'required-label': to.required }"></label>
    </div>
    <mat-radio-group [formControl]="formControl" [formlyAttributes]="field">
      <mat-radio-button
        *ngFor="let option of to.options"
        [value]="option.value"
        [class]="to.className"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
    <div *ngIf="showError && formControl.invalid" class="invalid-feedback">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  <!-- <div class="form-group">
      <label [innerHTML]="to.label" [ngClass]="{ 'required-label': to.required }"></label>
    </div>
    <mat-radio-group [formlyAttributes]="field">
      <mat-radio-button
        *ngFor="let option of to['radioOptions']"
        [value]="option.value"
        [class]="to['className']"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
    <div *ngIf="showError && formControl.invalid" class="invalid-feedback">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div> -->

  `,
styles: [`
    .custom-radio .mat-radio-button {
      margin-right: 1rem;
      display: inline-flex;
      align-items: center;
    }
    ::ng-deep .custom-radio .mat-radio-label-content {
      padding-left: 5px;
      padding-right: 5px;
    }
    .required-label::after {
      content: '*';
      color: red;
      margin-left: 4px;
    }
    .invalid-feedback {
      color: red;
      font-size: 0.8em;
      display: block;
    }
  `]
})
export class FormlyCustomRadio extends FieldType {
    @Input() radioOptions: any[] = [];
}
